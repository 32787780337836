"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchDevices = void 0;
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const CognitoIdentityProvider_1 = require("../utils/clients/CognitoIdentityProvider");
const types_1 = require("../utils/types");
const utils_2 = require("../utils/clients/CognitoIdentityProvider/utils");
const utils_3 = require("../../../utils");
// Cognito Documentation for max device
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ListDevices.html#API_ListDevices_RequestSyntax
const MAX_DEVICES = 60;
/**
 * Fetches devices that have been remembered using {@link rememberDevice}
 * for the currently authenticated user.
 *
 * @returns FetchDevicesOutput
 * @throws {@link ListDevicesException}
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function fetchDevices() {
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    const { tokens } = await (0, core_1.fetchAuthSession)();
    (0, types_1.assertAuthTokens)(tokens);
    const response = await (0, CognitoIdentityProvider_1.listDevices)({
        region: (0, utils_2.getRegion)(authConfig.userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.FetchDevices),
    }, {
        AccessToken: tokens.accessToken.toString(),
        Limit: MAX_DEVICES,
    });
    return parseDevicesResponse(response.Devices ?? []);
}
exports.fetchDevices = fetchDevices;
const parseDevicesResponse = async (devices) => {
    return devices.map(({ DeviceKey: id = '', DeviceAttributes = [], DeviceCreateDate, DeviceLastModifiedDate, DeviceLastAuthenticatedDate, }) => {
        let deviceName;
        const attributes = DeviceAttributes.reduce((attrs, { Name, Value }) => {
            if (Name && Value) {
                if (Name === 'device_name')
                    deviceName = Value;
                attrs[Name] = Value;
            }
            return attrs;
        }, {});
        return {
            id,
            name: deviceName,
            attributes,
            createDate: DeviceCreateDate
                ? new Date(DeviceCreateDate * 1000)
                : undefined,
            lastModifiedDate: DeviceLastModifiedDate
                ? new Date(DeviceLastModifiedDate * 1000)
                : undefined,
            lastAuthenticatedDate: DeviceLastAuthenticatedDate
                ? new Date(DeviceLastAuthenticatedDate * 1000)
                : undefined,
        };
    });
};
