"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMFAPreference = void 0;
const core_1 = require("@aws-amplify/core");
const utils_1 = require("@aws-amplify/core/internals/utils");
const signInHelpers_1 = require("../utils/signInHelpers");
const CognitoIdentityProvider_1 = require("../utils/clients/CognitoIdentityProvider");
const utils_2 = require("../utils/clients/CognitoIdentityProvider/utils");
const types_1 = require("../utils/types");
const utils_3 = require("../../../utils");
/**
 * Fetches the preferred MFA setting and enabled MFA settings for the user.
 *
 * @returns FetchMFAPreferenceOutput
 * @throws  -{@link GetUserException} : error thrown when the service fails to fetch MFA preference
 * and settings.
 * @throws AuthTokenConfigException - Thrown when the token provider config is invalid.
 */
async function fetchMFAPreference() {
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    const { tokens } = await (0, core_1.fetchAuthSession)({ forceRefresh: false });
    (0, types_1.assertAuthTokens)(tokens);
    const { PreferredMfaSetting, UserMFASettingList } = await (0, CognitoIdentityProvider_1.getUser)({
        region: (0, utils_2.getRegion)(authConfig.userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.FetchMFAPreference),
    }, {
        AccessToken: tokens.accessToken.toString(),
    });
    return {
        preferred: (0, signInHelpers_1.getMFAType)(PreferredMfaSetting),
        enabled: (0, signInHelpers_1.getMFATypes)(UserMFASettingList),
    };
}
exports.fetchMFAPreference = fetchMFAPreference;
