"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserAttributes = void 0;
const utils_1 = require("@aws-amplify/core/internals/utils");
const CognitoIdentityProvider_1 = require("../../utils/clients/CognitoIdentityProvider");
const utils_2 = require("../../utils/clients/CognitoIdentityProvider/utils");
const types_1 = require("../../utils/types");
const apiHelpers_1 = require("../../utils/apiHelpers");
const utils_3 = require("../../../../utils");
const fetchUserAttributes = async (amplify) => {
    const authConfig = amplify.getConfig().Auth?.Cognito;
    (0, utils_1.assertTokenProviderConfig)(authConfig);
    const { tokens } = await (0, utils_1.fetchAuthSession)(amplify, {
        forceRefresh: false,
    });
    (0, types_1.assertAuthTokens)(tokens);
    const { UserAttributes } = await (0, CognitoIdentityProvider_1.getUser)({
        region: (0, utils_2.getRegion)(authConfig.userPoolId),
        userAgentValue: (0, utils_3.getAuthUserAgentValue)(utils_1.AuthAction.FetchUserAttributes),
    }, {
        AccessToken: tokens.accessToken.toString(),
    });
    return (0, apiHelpers_1.toAuthUserAttribute)(UserAttributes);
};
exports.fetchUserAttributes = fetchUserAttributes;
