"use strict";
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildHttpRpcRequest = exports.getSharedHeaders = exports.defaultConfig = exports.cognitoUserPoolTransferHandler = void 0;
const core_1 = require("@aws-amplify/core");
const aws_client_utils_1 = require("@aws-amplify/core/internals/aws-client-utils");
const utils_1 = require("@aws-amplify/core/internals/utils");
const composers_1 = require("@aws-amplify/core/internals/aws-client-utils/composers");
/**
 * The service name used to sign requests if the API requires authentication.
 */
const SERVICE_NAME = 'cognito-idp';
/**
 * The endpoint resolver function that returns the endpoint URL for a given region.
 */
const endpointResolver = ({ region }) => {
    const authConfig = core_1.Amplify.getConfig().Auth?.Cognito;
    const customURL = authConfig?.userPoolEndpoint;
    const defaultURL = new utils_1.AmplifyUrl(`https://${SERVICE_NAME}.${region}.${(0, aws_client_utils_1.getDnsSuffix)(region)}`);
    return {
        url: customURL ? new utils_1.AmplifyUrl(customURL) : defaultURL,
    };
};
/**
 * A Cognito Identity-specific middleware that disables caching for all requests.
 */
const disableCacheMiddlewareFactory = () => (next, _) => async function disableCacheMiddleware(request) {
    request.headers['cache-control'] = 'no-store';
    return next(request);
};
/**
 * A Cognito Identity-specific transfer handler that does NOT sign requests, and
 * disables caching.
 *
 * @internal
 */
exports.cognitoUserPoolTransferHandler = (0, composers_1.composeTransferHandler)(aws_client_utils_1.unauthenticatedHandler, [disableCacheMiddlewareFactory]);
/**
 * @internal
 */
exports.defaultConfig = {
    service: SERVICE_NAME,
    endpointResolver,
    retryDecider: (0, aws_client_utils_1.getRetryDecider)(aws_client_utils_1.parseJsonError),
    computeDelay: aws_client_utils_1.jitteredBackoff,
    userAgentValue: (0, utils_1.getAmplifyUserAgent)(),
    cache: 'no-store',
};
/**
 * @internal
 */
const getSharedHeaders = (operation) => ({
    'content-type': 'application/x-amz-json-1.1',
    'x-amz-target': `AWSCognitoIdentityProviderService.${operation}`,
});
exports.getSharedHeaders = getSharedHeaders;
/**
 * @internal
 */
const buildHttpRpcRequest = ({ url }, headers, body) => ({
    headers,
    url,
    body,
    method: 'POST',
});
exports.buildHttpRpcRequest = buildHttpRpcRequest;
